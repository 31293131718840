import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate} from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";

import {
  Card,
  Stack,
  Box,
  Container,
  Typography,
  Breadcrumbs,
} from "@mui/material";

import useSettings from "../../hooks/useSettings";

import Page from "../../components/Page";
import { useEffect } from "react";
import { useState } from "react";

// ----------------------------------------------------------------------


export default function ActivityLog() {
  const { themeStretch } = useSettings();
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "user_role",
      headerName: "User Role",
      width: 200,
    },    
    {
      field: "user_name",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "activity",
      headerName: "Activity",
      width: 280,
    },
    {
      field: "date_time",
      headerName: "Date and Time",
      width: 280,
    },
  ];

  useEffect(() => {
    axios.get("https://qakdci.dstinnovate.com/2222/activity-log").then((res) => {
      const data = res.data;
      console.log("Data:",data);
      if (data.length) {
        setRows(data);
      }
    });
  }, []);
  

  return (
    <>
      <Helmet>
        <title> Activity Log | Kaizen Digital </title>
      </Helmet>
      <Page title="Page Two">
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Stack direction="column" spacing={0}>
              <Typography variant="h3" gutterBottom color="primary">
                Activity Log{" "}
              </Typography>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    Dashboard
                  </Typography>

                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                  Activity Log
                  </Typography>
                </Breadcrumbs>
              </div>
            </Stack>
          </Stack>
          <Card>
            <Box sx={{ overflow: "auto", width: "100%" }}>
              <DataGrid
                autoHeight
                slots={{ toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                disableRowSelectionOnClick
                loading={!rows.length}
              />
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}