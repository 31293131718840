import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, useLocation, Routes, Route } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
// components
import LoadingScreen from "../components/LoadingScreen";
import Protected from "./Protected";
import UnProtected from "./UnProtected";
import useDetails from "src/hooks/useDetails";
import EditDocBasicinfo from "src/pages/Doctors/EditDocBasicinfo";
import NewCarePlan_Doctor from "src/pages/CarePlan_Doctor/NewCarePlan_Doctor";
import EditCarePlan_Doctor from "src/pages/CarePlan_Doctor/EditCarePlan_Doctor";
import Appointments_Doctor from "src/pages/Appointments_Doctor/Appointments_Doctor";
import Appointments_Patient from "src/pages/Appointments_Patient/Appointments_Patient";
import Patients_Doctor from "src/pages/Patient_Doctor/Patients_Doctor";
import PatientsEditStepper_Doctor from "src/pages/Patient_Doctor/PatientsEditStepper_Doctor";
import HampView from "src/pages/Forms/HampView";
import AssignProgram from "src/pages/Programs/AssignProgram";
import AddPrograms from "src/pages/Programs/AddProgram";
import ProgramForms from "src/pages/Programs/CreateProgram";
import EditProgram from "src/pages/Programs/EditProgram";
import NewProgram from "src/pages/Programs/NewProgram";
// import PatientsEditStepper_Careadmin from "src/pages/Patient_CareAdmin/PatientsEditStepper_Careadmin";
// ----------------------------------------------------------------------
import ActivityLog from "src/pages/Login/ActivityLog";
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

const redirectionFn = (
  providePaths,
  local_url,
  component,
  pageType = "Found"
) => {
  return providePaths.map((providePath) => {
    providePath = providePath.split("/");
    return (pageType =
      "Found" && local_url === providePath ? (
        <Route path={providePath[0]}>
          <Route index element={<Navigate to={providePath[1]} />} />
          <Route path={local_url} element={component} />
        </Route>
      ) : (
        <Route path={providePath[0]}>
          <Route index element={<Navigate to={providePath[1]} />} />
          <Route path={local_url} element={<Unauthorized />} />
        </Route>
      ));
  });
};

const redirectPathsArr = [
  "patientmonitoring/questionnairesassignment",
  "user/users",
  "email/sendemail",
];

const getDashboardURLByRole = {
  'guest doctor':"doctordashboard",
  'patient guardian':"patientguardiandashboard",
  'care admin':"maindashboard",
  provider:"doctordashboard",
  patient:"patientdashboard",
  insurance:"insurancedashboard",
  'care admin manager':"maindashboard",
}
const DynamicRoutes = (List) => {
  const details = useDetails();
  return PATHS.map(({ local_url, remote_url, component }, ind) => {
    if (List.includes(remote_url)) {
      return (
        <Route key={ind} element={<DashboardLayout />}>
          <Route path="dashboard">
            {redirectionFn(redirectPathsArr, local_url, component)}
            <Route index element={<Navigate to={getDashboardURLByRole[details?.rolesname[0].toLowerCase()]} />} />
           
            <Route path={local_url} element={component} />
          </Route>
        </Route>
      );
    } else {
      return (
        <Route path="dashboard" key={ind}>
          {redirectionFn(redirectPathsArr, local_url, component, "NotFound")}
          <Route index path={local_url} element={<Unauthorized />} />
        </Route>
      );
    }
  });
};

export default function Router() {
  const location = useLocation();
  const details = useDetails();

  return (
    <Routes>
      <Route path="/">
        <Route element={<UnProtected />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="forgotpassword" element={<ForgotPass />} />
          <Route path="resetpassword1/:hash" element={<ResetPassword1 />} />
        </Route>
        <Route index element={<Navigate to="dashboard" />} />

        <Route element={<Protected />}>
          {DynamicRoutes(details?.permission ? details?.permission : [])}
        </Route>
      </Route>

      <Route path="/404" element={<PageNotFound />} />
      <Route path="/unauthorized" element={<Unauthorized />} />

      <Route
        path="*"
        element={<Navigate to="/404" state={{ from: location }} replace />}
      />
    </Routes>
  );
}
const MyProfile = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile")));
const MyProfileCareadmin = Loadable(lazy(() => import("../pages/Patient_CareAdmin/PatientProfile_Careadmin/PatientProfile_Careadmin")));
const MyProfileDoctor = Loadable(lazy(() => import("../pages/Patient_Doctor/PatientProfile_Doctor/PatientProfile_Doctor")));
// Dashboard
const PageOne = Loadable(lazy(() => import("../pages/PageOne")));
const PageOne_insurance = Loadable(lazy(() => import("../pages/PageOne_insurance")));
const PageOne_patients = Loadable(lazy(() => import("../pages/PageOne_patients")));
const PageOne_doctor = Loadable(lazy(() => import("../pages/PageOne_doctor")));
const PageOne_guardian = Loadable(lazy(() => import("../pages/PageOne_guardian")));

const PatientProfile = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientProfile")));
const ProfileCover = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/ProfileCover")));
const PatientFormList = Loadable(lazy(() => import("../pages/PatientsDasboard/PatientFormList")));
const PatientClinicalNotes = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientClinicalNotes")));
const Documents = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/Documents")));
const PatientAppointments = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientAppointments")));
const PatientCarePlan = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientCarePlan")));
const PatientDetails = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails")));
const PatientInfo = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientInfo")));
const PatientInvoices = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientInvoices")));
const PatientsEdit = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails")));
const PatientSendEmail = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientSendEmail")));

const PatientBasicInfo = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientBasicInfo")));
const PatientCounter = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientCounter")));
const PatientCreditPolicy = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientCreditPolicy")));
const PatientDoctorProvider = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientDoctorProvider")));
const PatientEmergency = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientEmergency")));
const PatientFinancial = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientFinancial")));
const PatientHealthProvider = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientHealthProvider")));
const PatientHipaa = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientHipaa")));
const PatientInsuranceInformation = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientInsuranceInformation")));
const PatientMedication = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientMedication")));
const PatientPrescription = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientDetails/PatientPrescription")));

const MyVitals = Loadable(lazy(() => import("../pages/PatientsDasboard/MyProfile/PatientVitalsLog")));
const AddVitals = Loadable(lazy(() => import("../pages/PatientsDasboard/AddVitals")));

const ImportData = Loadable(
  lazy(() => import("../pages/Department/ImportData"))
);

// Patient Registration
const PatientRegistration = Loadable(
  lazy(() => import("../pages/PatientRegistration/PatientRegistration"))
);
const AddPatient = Loadable(
  lazy(() => import("../pages/PatientRegistration/Addpatient"))
);
// const AssignDoctor = Loadable(
//   lazy(() => import("../pages/PatientRegistration/AssignDoctor"))
// );
// const InsuranceInformation = Loadable(
//   lazy(() => import("../pages/PatientRegistration/InsuranceInformation"))
// );
// const AddingPatient = Loadable(
//   lazy(() => import("../pages/PatientRegistration/AddingPatient"))
// );
const EditingPatient = Loadable(
  lazy(() => import("../pages/PatientRegistration/EditingPatient"))
);

// Insurance
const Insurance = Loadable(lazy(() => import("../pages/Insurance/Insurance")));
const InsuranceInformation1 = Loadable(
  lazy(() => import("../pages/Insurance/InsuranceInformation1"))
);
const PersonalInfo = Loadable(
  lazy(() => import("../pages/Insurance/PersonalInfo"))
);
// const EditInsuranceInfo = Loadable(
//   lazy(() => import("../pages/Insurance/EditInsuranceInfo"))
// );
// const EditPersonalInfo = Loadable(
//   lazy(() => import("../pages/Insurance/EditPersonalInfo"))
// );

// Patients
const AddPatient1 = Loadable(
  lazy(() => import("../pages/Patient/Addpatient1"))
);
const PatientsMainStepper = Loadable(
  lazy(() => import("../pages/Patient/PatientsMainStepper"))
);
const PatientsEditStepper = Loadable(
  lazy(() => import("../pages/Patient/PatientsEditStepper"))
);

const PatientInsuranceInfo = Loadable(
  lazy(() => import("../pages/Patient/PatientInsuranceInfo"))
);
const InsuranceStepper = Loadable(
  lazy(() => import("../pages/Insurance/InsuranceStepper"))
);
const Agreement = Loadable(lazy(() => import("../pages/Patient/Agreement")));
const Consent = Loadable(lazy(() => import("../pages/Patient/Consent")));
const Emergency = Loadable(lazy(() => import("../pages/Patient/Emergency")));
const Financial = Loadable(lazy(() => import("../pages/Patient/Financial")));
const HealthProvider = Loadable(
  lazy(() => import("../pages/Patient/HealthProvider"))
);
const Hipaa = Loadable(lazy(() => import("../pages/Patient/Hipaa")));
const InsInfo = Loadable(lazy(() => import("../pages/Patient/InsInfo")));
const Medications = Loadable(
  lazy(() => import("../pages/Patient/Medications"))
);
const NonPrescription = Loadable(
  lazy(() => import("../pages/Patient/NonPrescription"))
);
const Patients = Loadable(lazy(() => import("../pages/Patient/Patients")));
const Prescription = Loadable(
  lazy(() => import("../pages/Patient/Prescription"))
);

//Patient_CareAdmin
const Patients_Careadmin = Loadable(lazy(() => import("../pages/Patient_CareAdmin/Patients_Careadmin")));
const PatientsEditStepper_Careadmin = Loadable(lazy(() => import("../pages/Patient_CareAdmin/PatientsEditStepper_Careadmin")));

// Forms View
const RpcsView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Rpcs"))
);
const HitView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Hit"))
);
const OxfordView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/OxfordForm"))
);
const PCLView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/PCL5"))
);
const BdiView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Bdi"))
);
const NpiView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Npi"))
);
const EssView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Ess"))
);
const CissView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Ciss"))
);
const MedicalView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Medical"))
);
const PhqView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Phq"))
);
const NphqView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Nphq"))
);
const GadView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Gad"))
);
const MnaView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Mna"))
);
const MbView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Mb"))
);
const LawtonView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Lawton"))
);
const FacialPainView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/FacialPain"))
);
const AdhdView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Adhd"))
);
const HippaView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Hipaa2"))
);
const SunshineActView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/SunshineAct"))
);
const NeuroConsentView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/NeuroConsent"))
);
const CpcrView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Cpcr"))
);
const CaicView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/Caic"))
);
const ActivitiesFormView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/ActivitiesForm"))
);
const StigmaFormView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/StigmaForm"))
);
const DrFoxView = Loadable(
  lazy(() => import("../pages/Forms_Careadmin/DrFox"))
);
const NeuroQol = Loadable(lazy(() => import("../pages/Forms/NeuroQol")));



// Patient Monitoring
const QuestionnairesAssignment = Loadable(
  lazy(() => import("../pages/PatientMonitoring/QuestionnairesAssignment"))
);
const TmpHistory = Loadable(
  lazy(() => import("../pages/PatientMonitoring/TmpHistory"))
);
const TmpGraph = Loadable(
  lazy(() => import("../pages/PatientMonitoring/TmpGraph"))
);
const TmpQuestionnaires = Loadable(
  lazy(() => import("../pages/PatientMonitoring/TmpQuestionnaires"))
);
const BdiTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Bdi")));
const HitTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Hit")));
const OxfordTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/OxfordForm")));
const PclTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/PCL5")));
const RpcsTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Rpcs")));
const EssTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Ess")));
const CissTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Ciss")));
const NpiTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Npi")));
const NphqTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Nphq")));
const PhqTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Phq")));
const GadTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Gad")));


// const RpcsTmp = Loadable(lazy(() => import("../pages/PatientMonitoring/Rpcs")));

const DiagnosticUpload = Loadable(lazy(() => import("../pages/PatientMonitoring/DiagnosticUpload")));
const DiagnosticView = Loadable(lazy(() => import("../pages/PatientMonitoring/DiagnosticView")));



const Bdi = Loadable(lazy(() => import("../pages/Forms/Bdi")));
const Ciss = Loadable(lazy(() => import("../pages/Forms/Ciss")));
const Ess = Loadable(lazy(() => import("../pages/Forms/Ess")));
const Hit = Loadable(lazy(() => import("../pages/Forms/Hit")));
const HampInsert = Loadable(lazy(() => import("../pages/Forms/HampInsert")));

const Lawton = Loadable(
  lazy(() => import("../pages/Forms/Lawton"))
);
const Mb = Loadable(lazy(() => import("../pages/Forms/Mb")));
const Medical = Loadable(
  lazy(() => import("../pages/Forms/Medical"))
);
const OxfordForm = Loadable(lazy(() => import("../pages/Forms/OxfordForm")));
const PclForm = Loadable(lazy(() => import("../pages/Forms/PCL5")));
const Mna = Loadable(lazy(() => import("../pages/Forms/Mna")));
const Nphq = Loadable(lazy(() => import("../pages/Forms/Nphq")));
const Npi = Loadable(lazy(() => import("../pages/Forms/Npi")));
const Phq = Loadable(lazy(() => import("../pages/Forms/Phq")));
const Rpcs = Loadable(lazy(() => import("../pages/Forms/Rpcs")));

// Pending Forms
const PendingForms = Loadable(
  lazy(() => import("../pages/PendingForms/PendingForms"))
);

// All Patient Vitals
const PatientVitals = Loadable(
  lazy(() => import("../pages/PatientVitals/PatientVitals"))
);
const AddPatientVitals = Loadable(
  lazy(() => import("../pages/PatientVitals/AddPatientVitals"))
);
const PatientVitals_Doctor = Loadable(
  lazy(() => import("../pages/PatientVitals/PatientVitals_Doctor"))
);

// Appointments
const Appointments = Loadable(
  lazy(() => import("../pages/Appointments/Appointments"))
);
const NewAppointment = Loadable(
  lazy(() => import("../pages/Appointments/NewAppointment"))
);
const EditAppointment = Loadable(
  lazy(() => import("../pages/Appointments/EditAppointment"))
);

// Care Plan
const CarePlan = Loadable(lazy(() => import("../pages/CarePlan/CarePlan")));
const CarePlan_Doctor = Loadable(lazy(() => import("../pages/CarePlan_Doctor/CarePlan_Doctor")));
const NewCarePlan = Loadable(
  lazy(() => import("../pages/CarePlan/NewCarePlan"))
);
const EditCarePlan = Loadable(
  lazy(() => import("../pages/CarePlan/EditCarePlan"))
);

// Departments
const Departments = Loadable(
  lazy(() => import("../pages/Department/Departments"))
);
const NewDepartments = Loadable(
  lazy(() => import("../pages/Department/NewDepartments"))
);
const EditDepartments = Loadable(
  lazy(() => import("../pages/Department/EditDepartment"))
);

// Doctors
const AddDoctor = Loadable(lazy(() => import("../pages/Doctors/AddDoctor")));
const EditDoctor = Loadable(lazy(() => import("../pages/Doctors/EditDoctor")));
const DocAddress = Loadable(lazy(() => import("../pages/Doctors/DocAddress")));
const DocAppointmentinfo = Loadable(
  lazy(() => import("../pages/Doctors/DocAppointmentinfo"))
);
const DocBasicinfo = Loadable(
  lazy(() => import("../pages/Doctors/DocBasicinfo"))
);
const DocHolidays = Loadable(
  lazy(() => import("../pages/Doctors/DocHolidays"))
);
const Doctors = Loadable(lazy(() => import("../pages/Doctors/Doctors")));
const DocWebdata = Loadable(lazy(() => import("../pages/Doctors/DocWebdata")));

// User
const AddAddress = Loadable(lazy(() => import("../pages/User/AddAddress")));
const AddBasicinfo = Loadable(lazy(() => import("../pages/User/AddBasicinfo")));
const AddUser = Loadable(lazy(() => import("../pages/User/AddUser")));
const EditUser = Loadable(lazy(() => import("../pages/User/EditUser")));
const ListOfPassword = Loadable(
  lazy(() => import("../pages/User/ListOfPassword"))
);
const EditListOfPassword = Loadable(
  lazy(() => import("../pages/User/EditListOfPassword"))
);
const NewUserRole = Loadable(lazy(() => import("../pages/User/NewUserRole")));
const EditUserRole = Loadable(lazy(() => import("../pages/User/EditUserRole_CareAdmin")));
const UserRole = Loadable(lazy(() => import("../pages/User/UserRole")));
const Users = Loadable(lazy(() => import("../pages/User/Users")));

// Forms
const ActivitiesForm = Loadable(
  lazy(() => import("../pages/Forms/ActivitiesForm"))
);
const Adhd = Loadable(lazy(() => import("../pages/Forms/Adhd")));
const AssignForms = Loadable(lazy(() => import("../pages/Forms/AssignForms")));
const EditAssignedForms = Loadable(
  lazy(() => import("../pages/Forms/EditAssignedForms"))
);
const Caic = Loadable(lazy(() => import("../pages/Forms/Caic")));
const Cpcr = Loadable(lazy(() => import("../pages/Forms/Cpcr")));
const FacialPain = Loadable(lazy(() => import("../pages/Forms/FacialPain")));
const Forms = Loadable(lazy(() => import("../pages/Forms/Forms")));
const Gad = Loadable(lazy(() => import("../pages/Forms/Gad")));
const StigmaForm = Loadable(lazy(() => import("../pages/Forms/StigmaForm")));
const SunshineAct = Loadable(lazy(() => import("../pages/Forms/SunshineAct")));
const NeuroConsent = Loadable(
  lazy(() => import("../pages/Forms/NeuroConsent"))
);
const NewForm = Loadable(lazy(() => import("../pages/Forms/NewForm")));
const Hipaa2 = Loadable(lazy(() => import("../pages/Forms/Hipaa2")));
const DrFox = Loadable(lazy(() => import("../pages/Forms/DrFox")));

// Email
const CareGiver = Loadable(lazy(() => import("../pages/Email/CareGiver")));
const EmailLog = Loadable(lazy(() => import("../pages/Email/EmailLog")));
const EmailSettings = Loadable(
  lazy(() => import("../pages/Email/EmailSettings"))
);
const EmailTemplate = Loadable(
  lazy(() => import("../pages/Email/EmailTemplate"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/Email/ForgotPassword"))
);
const NewAdmin = Loadable(lazy(() => import("../pages/Email/NewAdmin")));
const NewAppointment1 = Loadable(
  lazy(() => import("../pages/Email/NewAppointment1"))
);
const NewInvoice = Loadable(lazy(() => import("../pages/Email/NewInvoice")));
const NewPatient = Loadable(lazy(() => import("../pages/Email/NewPatient")));
const NewRequest = Loadable(lazy(() => import("../pages/Email/NewRequest")));
const NewWebsite = Loadable(lazy(() => import("../pages/Email/NewWebsite")));
const PaymentConfirmation = Loadable(
  lazy(() => import("../pages/Email/PaymentConfirmation"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/Email/ResetPassword"))
);
const SendBulkEmail = Loadable(
  lazy(() => import("../pages/Email/SendBulkEmail"))
);
const SendEmail = Loadable(lazy(() => import("../pages/Email/SendEmail")));

// Settings
const Address = Loadable(lazy(() => import("../pages/Settings/Address")));
const BasicInfo = Loadable(lazy(() => import("../pages/Settings/BasicInfo")));
const ClinicalNotes = Loadable(
  lazy(() => import("../pages/Settings/ClinicalNotes"))
);
const ExpenseType = Loadable(
  lazy(() => import("../pages/Settings/ExpenseType"))
);
const InvoiceItems = Loadable(
  lazy(() => import("../pages/Settings/InvoiceItems"))
);
const NewExpense = Loadable(lazy(() => import("../pages/Settings/NewExpense")));
const NewItem = Loadable(lazy(() => import("../pages/Settings/NewItem")));
const NewNote = Loadable(lazy(() => import("../pages/Settings/NewNote")));
const NewPayment = Loadable(lazy(() => import("../pages/Settings/NewPayment")));
const NewSupplier = Loadable(
  lazy(() => import("../pages/Settings/NewSupplier"))
);
const NewTax = Loadable(lazy(() => import("../pages/Settings/NewTax")));
const PaymentGateway = Loadable(
  lazy(() => import("../pages/Settings/PaymentGateway"))
);
const PaymentMethod = Loadable(
  lazy(() => import("../pages/Settings/PaymentMethod"))
);
const SocialLink = Loadable(lazy(() => import("../pages/Settings/SocialLink")));
const Suppliers = Loadable(lazy(() => import("../pages/Settings/Suppliers")));
const SystemInfo = Loadable(lazy(() => import("../pages/Settings/SystemInfo")));
const TaxRate = Loadable(lazy(() => import("../pages/Settings/TaxRate")));

// Blog
const Blog = Loadable(lazy(() => import("../pages/Blog/Blog")));
const Category = Loadable(lazy(() => import("../pages/Blog/Category")));
const AddCategory = Loadable(lazy(() => import("../pages/Blog/AddCategory")));
const EditCategory = Loadable(lazy(() => import("../pages/Blog/EditCategory")));
const Comment = Loadable(lazy(() => import("../pages/Blog/Comment")));
const NewPost = Loadable(lazy(() => import("../pages/Blog/NewPost")));
const EditPost = Loadable(lazy(() => import("../pages/Blog/EditPost")));

// Login
const LoginForm = Loadable(lazy(() => import("../pages/Login/Login")));

// Terms and Conditions
const TermsAndCondition = Loadable(lazy(() => import("../pages/Login/TermsAndCondition")));

// Forgot Password

const ForgotPass = Loadable(lazy(() => import("../pages/Login/ForgotPassword"))
);

// Reset Password
const ResetPassword1 = Loadable(
  lazy(() => import("../pages/Login/ResetPassword1"))
);

// Invoice
const Invoice = Loadable(lazy(() => import("../pages/Invoice/Invoice")));

// Expense
const Expense = Loadable(lazy(() => import("../pages/Expense/Expense")));
const PageNotFound = Loadable(lazy(() => import("../pages/PageNotFound")));
const Unauthorized = Loadable(lazy(() => import("../pages/Unauthorized")));
const FormsList = Loadable(lazy(() => import("../pages/Forms/FormsList")));

export const PATHS = [
  {
    local_url: "maindashboard",
    remote_url: "dashboard",
    component: <PageOne />,
  },
  {
    local_url: "insurancedashboard",
    remote_url: "insurancedashboard",
    component: <PageOne_insurance />,
  },
  {
    local_url: "patientdashboard",
    remote_url: "patientdashboard",
    component: <PageOne_patients />,
  },
  {
    local_url: "patientguardiandashboard",
    remote_url: "patientguardiandashboard",
    component: <PageOne_guardian />,
  },
  {
    local_url: "doctordashboard",
    remote_url: "doctordashboard",
    component: <PageOne_doctor />,
  },
  {
    local_url: "patientregistration",
    remote_url: "patientregistration",
    component: <PatientRegistration />,
  },
  {
    local_url: "patientregistration/addpatient",
    remote_url: "patientregistration/addpatient",
    component: <AddPatient />,
  },
  {
    local_url: "patientregistration/editingpatient",
    remote_url: "patientregistration/editingpatient",
    component: <EditingPatient />,
  },
  {
    local_url: "insurance",
    remote_url: "insurance",
    component: <Insurance />,
  },
  // {
  //   local_url: "insurance/personalinfo",
  //   remote_url: "insurance/personalinfo",
  //   component: <PersonalInfo />,
  // },
  {
    local_url: "forms/editassignedforms/:id",
    remote_url: "forms/editassignedforms",
    component: <EditAssignedForms />,
  },
  {
    local_url: "insurance/insuranceinformation1",
    remote_url: "insurance/insuranceinformation1",
    component: <InsuranceInformation1 />,
  },
  // {
  //   local_url: "insurance/editpersonalinfo",
  //   remote_url: "insurance/editpersonalinfo",
  //   component: <EditPersonalInfo />,
  // },
  // {
  //   local_url: "insurance/editinsuranceinfo",
  //   remote_url: "insurance/editinsuranceinfo",
  //   component: <EditInsuranceInfo />,
  // },
  {
    local_url: "validation",
    remote_url: "validation",
    component: <Insurance />,
  },
  {
    local_url: "insurance/editinsuranceinfo",
    remote_url: "insurance/editinsuranceinfo",
    component: <InsuranceStepper />,
  },
  {
    local_url: "insurance/editinsuranceinfo/:id",
    remote_url: "insurance/editinsuranceinfo",
    component: <InsuranceStepper />,
  },
  // {
  //   local_url: "patients",
  //   remote_url: "patients",
  //   component: <Patients />,
  // },
  {
    local_url: "patients",
    remote_url: "patients",
    component: <Patients_Careadmin />,
  },
  {
    local_url: "mypatients",
    remote_url: "mypatients",
    component: <Patients_Doctor />,
  },
  {
    local_url: "patients/patientsmainstepper",
    remote_url: "patients/patientsmainstepper",
    component: <PatientsMainStepper />,
  },
  {
    local_url: "patients/patientseditstepper",
    remote_url: "patients/patientseditstepper",
    component: <PatientsEditStepper />,
  },
  {
    local_url: "patients/edit",
    remote_url: "patients/edit",
    component: <PatientsEditStepper_Careadmin />,
  },
  {
    local_url: "mypatients/edit",
    remote_url: "mypatients/edit",
    component: <PatientsEditStepper_Doctor />,
  },
  {
    local_url: "patients/edit/:id",
    remote_url: "patients/edit",
    component: <PatientsEditStepper_Careadmin />,
  },
  {
    local_url: "patients/addpatient1",
    remote_url: "patients/addpatient1",
    component: <AddPatient1 />,
  },
  {
    local_url: "patients/patientinsuranceinfo",
    remote_url: "patients/patientinsuranceinfo",
    component: <PatientInsuranceInfo />,
  },
  {
    local_url: "patients/emergency",
    remote_url: "patients/emergency",
    component: <Emergency />,
  },
  {
    local_url: "patients/healthprovider",
    remote_url: "patients/healthprovider",
    component: <HealthProvider />,
  },
  {
    local_url: "patients/medications",
    remote_url: "patients/medications",
    component: <Medications />,
  },
  {
    local_url: "patients/prescriptions",
    remote_url: "patients/prescriptions",
    component: <Prescription />,
  },
  {
    local_url: "patients/nonprescriptions",
    remote_url: "patients/nonprescriptions",
    component: <NonPrescription />,
  },
  {
    local_url: "patients/hipaa",
    remote_url: "patients/hipaa",
    component: <Hipaa />,
  },
  {
    local_url: "patients/agreement",
    remote_url: "patients/agreement",
    component: <Agreement />,
  },
  {
    local_url: "patients/financial",
    remote_url: "patients/financial",
    component: <Financial />,
  },
  {
    local_url: "patients/consent",
    remote_url: "patients/consent",
    component: <Consent />,
  },
  {
    local_url: "patientmonitoring/questionnairesassignment",
    remote_url: "patientmonitoring/questionnairesassignment",
    component: <QuestionnairesAssignment />,
  },
  {
    local_url: "patientmonitoring/tmphistory",
    remote_url: "patientmonitoring/tmphistory",
    component: <TmpHistory />,
  },
  {
    local_url: "patientmonitoring/tmpgraph",
    remote_url: "patientmonitoring/tmpgraph",
    component: <TmpGraph />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires",
    remote_url: "patientmonitoring/tmpquestionnaires",
    component: <TmpQuestionnaires />,
  },
  {
    local_url: "myhealthrecord/questionnaires",
    remote_url: "myhealthrecord/questionnaires",
    component: <PatientFormList />,
  },
  {
    local_url: "myhealthrecord/questionnairesassignment",
    remote_url: "myhealthrecord/questionnairesassignment",
    component: <QuestionnairesAssignment />,
  },
  {
    local_url: "myhealthrecord/myhistory",
    remote_url: "myhealthrecord/myhistory",
    component: <TmpHistory />,
  },
  {
    local_url: "myhealthrecord/mygraphs",
    remote_url: "myhealthrecord/mygraphs",
    component: <TmpGraph />,
  },
  // {
  //   local_url: "myhealthrecord/questionnaires",
  //   remote_url: "myhealthrecord/questionnaires",
  //   component: <TmpQuestionnaires />,
  // },

  {
    local_url: "patientmonitoring/tmpquestionnaires/bdi",
    remote_url: "patientmonitoring/tmpquestionnaires/bdi",
    component: <BdiTmp />,
  },
  {
    local_url: "forms/bdi",
    remote_url: "forms/bdi",
    component: <Bdi />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/ciss",
    remote_url: "patientmonitoring/tmpquestionnaires/ciss",
    component: <CissTmp />,
  },
  {
    local_url: "forms/ciss",
    remote_url: "forms/ciss",
    component: <Ciss />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/ess",
    remote_url: "patientmonitoring/tmpquestionnaires/ess",
    component: <EssTmp />,
  },
  {
    local_url: "forms/ess",
    remote_url: "forms/ess",
    component: <Ess />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/hit",
    remote_url: "patientmonitoring/tmpquestionnaires/hit",
    component: <HitTmp />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/odq",
    remote_url: "patientmonitoring/tmpquestionnaires/odq",
    component: <OxfordTmp />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/pcl",
    remote_url: "patientmonitoring/tmpquestionnaires/pcl",
    component: <PclTmp />,
  },
    {
    local_url: "forms/hit",
    remote_url: "forms/hit",
    component: <Hit />,
  },
  {
    local_url: "forms/odq",
    remote_url: "forms/odq",
    component: <OxfordForm />,
  },
  {
    local_url: "forms/pcl",
    remote_url: "forms/pcl",
    component: <PclForm />,
  },
  {
    local_url: "myhealthrecord/hamp",
    remote_url: "myhealthrecord/hamp",
    component: <HampInsert />,
  },
  {
    local_url: "hamp/view",
    remote_url: "hamp/view",
    component: <HampView />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/lawton",
    remote_url: "patientmonitoring/tmpquestionnaires/lawton",
    component: <Lawton />,
  },
  {
    local_url: "forms/lawton",
    remote_url: "forms/lawton",
    component: <Lawton />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/mb",
    remote_url: "patientmonitoring/tmpquestionnaires/mb",
    component: <Mb />,
  },
  {
    local_url: "forms/mb",
    remote_url: "forms/mb",
    component: <Mb />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/medical",
    remote_url: "patientmonitoring/tmpquestionnaires/medical",
    component: <Medical />,
  },
  {
    local_url: "forms/medical",
    remote_url: "forms/medical",
    component: <Medical />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/mna",
    remote_url: "patientmonitoring/tmpquestionnaires/mna",
    component: <Mna />,
  },
  {
    local_url: "forms/mna",
    remote_url: "forms/mna",
    component: <Mna />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/nphq",
    remote_url: "patientmonitoring/tmpquestionnaires/nphq",
    component: <NphqTmp />,
  },
  {
    local_url: "forms/nphq",
    remote_url: "forms/nphq",
    component: <Nphq />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/npi",
    remote_url: "patientmonitoring/tmpquestionnaires/npi",
    component: <NpiTmp />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/phq",
    remote_url: "patientmonitoring/tmpquestionnaires/phq",
    component: <PhqTmp />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/gad",
    remote_url: "patientmonitoring/tmpquestionnaires/gad",
    component: <GadTmp />,
  },
  {
    local_url: "forms/npi",
    remote_url: "forms/npi",
    component: <Npi />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/phq",
    remote_url: "patientmonitoring/tmpquestionnaires/phq",
    component: <Phq />,
  },
  {
    local_url: "forms/phq",
    remote_url: "forms/phq",
    component: <Phq />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/rpcs",
    remote_url: "patientmonitoring/tmpquestionnaires/rpcs",
    component: <RpcsTmp />,
  },
  {
    local_url: "patientmonitoring/diagnosticreports",
    remote_url: "patientmonitoring/diagnosticreports",
    component: <DiagnosticUpload />,
  },
  {
    local_url: "patientmonitoring/diagnosticreports/view",
    remote_url: "patientmonitoring/diagnosticreports/view",
    component: <DiagnosticView />,
  },
  {
    local_url: "pendingforms",
    remote_url: "pendingforms",
    component: <PendingForms />,
  },
  {
    local_url: "allpatientvitals",
    remote_url: "allpatientvitals",
    component: <PatientVitals />,
  },
  {
    local_url: "addpatientvitals",
    remote_url: "addpatientvitals",
    component: <AddPatientVitals />,
  },
  {
    local_url: "patientvitals",
    remote_url: "patientvitals",
    component: <PatientVitals_Doctor />,
  },
  // {
  //   local_url: "myvitals",
  //   remote_url: "myvitals",
  //   component: <PatientVitals />,
  // },
  {
    local_url: "appointments",
    remote_url: "appointments",
    component: <Appointments />,
  },
  {
    local_url: "appointments_doctor",
    remote_url: "appointments_doctor",
    component: <Appointments_Doctor />,
  },
  {
    local_url: "myappointments",
    remote_url: "myappointments",
    component: <Appointments_Patient />,
  },
  {
    local_url: "appointments/newappointment",
    remote_url: "appointments/newappointment",
    component: <NewAppointment />,
  },
  {
    local_url: "appointments/editappointment",
    remote_url: "appointments/editappointment",
    component: <EditAppointment />,
  },
  {
    local_url: "invoice",
    remote_url: "invoice",
    component: <Invoice />,
  },
  {
    local_url: "expense",
    remote_url: "expense",
    component: <Expense />,
  },
  {
    local_url: "careplan",
    remote_url: "careplan",
    component: <CarePlan />,
  },
  {
    local_url: "patientcareplan",
    remote_url: "patientcareplan",
    component: <CarePlan_Doctor />,
  },
  {
    local_url: "mycareplan",
    remote_url: "mycareplan",
    component: <CarePlan />,
  },
  {
    local_url: "careplan/newcareplan",
    remote_url: "careplan/newcareplan",
    component: <NewCarePlan />,
  },
  {
    local_url: "careplan/editcareplan",
    remote_url: "careplan/editcareplan",
    component: <EditCarePlan />,
  },
  {
    local_url: "patientcareplan/newcareplan_d",
    remote_url: "patientcareplan/newcareplan_d",
    component: <NewCarePlan_Doctor />,
  },
  {
    local_url: "patientcareplan/editcareplan_d",
    remote_url: "patientcareplan/editcareplan_d",
    component: <EditCarePlan_Doctor />,
  },
  {
    local_url: "departments",
    remote_url: "departments",
    component: <Departments />,
  },
  {
    local_url: "departments/newdepartments",
    remote_url: "departments/newdepartments",
    component: <NewDepartments />,
  },
  {
    local_url: "departments/editdepartments",
    remote_url: "departments/editdepartments",
    component: <EditDepartments />,
  },
  {
    local_url: "departments/editdepartments/:ids",
    remote_url: "departments/editdepartments",
    component: <EditDepartments />,
  },
  {
    local_url: "departments/importdata",
    remote_url: "departments/importdata",
    component: <ImportData />,
  },
  {
    local_url: "doctors",
    remote_url: "doctors",
    component: <Doctors />,
  },
  {
    local_url: "doctors/adddoctor",
    remote_url: "doctors/adddoctor",
    component: <AddDoctor />,
  },
  {
    local_url: "doctors/editdoctor",
    remote_url: "doctors/editdoctor",
    component: <EditDoctor />,
  },
  {
    local_url: "doctors/editdoctor/:id",
    remote_url: "doctors/editdoctor",
    component: <EditDocBasicinfo />,
  },
  {
    local_url: "user/users",
    remote_url: "user/users",
    component: <Users />,
  },
  {
    local_url: "user/adduser",
    remote_url: "user/adduser",
    component: <AddUser />,
  },
  {
    local_url: "user/edituser",
    remote_url: "user/edituser",
    component: <EditUser />,
  },
  {
    local_url: "user/listofpassword",
    remote_url: "user/listofpassword",
    component: <ListOfPassword />,
  },
  {
    local_url: "user/editlistofpassword",
    remote_url: "user/editlistofpassword",
    component: <EditListOfPassword />,
  },
  {
    local_url: "user/userrole",
    remote_url: "user/userrole",
    component: <UserRole />,
  },
  {
    local_url: "user/newuserrole",
    remote_url: "user/newuserrole",
    component: <NewUserRole />,
  },
  {
    local_url: "user/edituserrole",
    remote_url: "user/edituserrole",
    component: <EditUserRole />,
  },
  {
    local_url: "email/sendemail",
    remote_url: "email/sendemail",
    component: <SendEmail />,
  },
  {
    local_url: "email/sendbulkemail",
    remote_url: "email/sendbulkemail",
    component: <SendBulkEmail />,
  },
  {
    local_url: "email/emaillog",
    remote_url: "email/emaillog",
    component: <EmailLog />,
  },
  {
    local_url: "email/emailsettings",
    remote_url: "email/emailsettings",
    component: <EmailSettings />,
  },
  {
    local_url: "email/emailtemplate",
    remote_url: "email/emailtemplate",
    component: <EmailTemplate />,
  },
  {
    local_url: "email/emailtemplate",
    remote_url: "email/emailtemplate",
    component: <EmailTemplate />,
  },
  {
    local_url: "blog/blogs",
    remote_url: "blog/blogs",
    component: <Blog />,
  },
  {
    local_url: "blog/category",
    remote_url: "blog/category",
    component: <Category />,
  },
  {
    local_url: "blog/addcategory",
    remote_url: "blog/addcategory",
    component: <AddCategory />,
  },
  {
    local_url: "blog/editcategory",
    remote_url: "blog/editcategory",
    component: <EditCategory />,
  },
  {
    local_url: "blog/comment",
    remote_url: "blog/comment",
    component: <Comment />,
  },
  {
    local_url: "blog/newpost",
    remote_url: "blog/newpost",
    component: <NewPost />,
  },
  {
    local_url: "blog/editpost",
    remote_url: "blog/editpost",
    component: <EditPost />,
  },
  {
    local_url: "forms/allforms",
    remote_url: "forms/allforms",
    component: <Forms />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/katz",
    remote_url: "patientmonitoring/tmpquestionnaires/katz",
    component: <ActivitiesForm />,
  },
  {
    local_url: "forms/katz",
    remote_url: "forms/katz",
    component: <ActivitiesForm />,
  },
  
  {
    local_url: "patientmonitoring/tmpquestionnaires/adhd",
    remote_url: "patientmonitoring/tmpquestionnaires/adhd",
    component: <Adhd />,
  },
  {
    local_url: "forms/adhd",
    remote_url: "forms/adhd",
    component: <Adhd />,
  },
  {
    local_url: "forms/assignforms",
    remote_url: "forms/assignforms",
    component: <AssignForms />,
  },
  {
    local_url: "forms/editassignedforms",
    remote_url: "forms/editassignedforms",
    component: <EditAssignedForms />,
  },
  {
    local_url: "forms/formslist",
    remote_url: "forms/formslist",
    component: <FormsList />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/caic",
    remote_url: "patientmonitoring/tmpquestionnaires/caic",
    component: <Caic />,
  },
  {
    local_url: "forms/caic",
    remote_url: "forms/caic",
    component: <Caic />,
  },
  {
    local_url: "forms/cpcr",
    remote_url: "forms/cpcr",
    component: <Cpcr />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/cpcr",
    remote_url: "patientmonitoring/tmpquestionnaires/cpcr",
    component: <Cpcr />,
  },
  {
    local_url: "facialpain/facialPain",
    remote_url: "facialpain/facialPain",
    component: <FacialPain />,
  },
  
  {
    local_url: "patientmonitoring/tmpquestionnaires/fpq",
    remote_url: "patientmonitoring/tmpquestionnaires/fpq",
    component: <FacialPain />,
  },
  {
    local_url: "forms/fpq",
    remote_url: "forms/fpq",
    component: <FacialPain />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/gad",
    remote_url: "patientmonitoring/tmpquestionnaires/gad",
    component: <Gad />,
  },
  {
    local_url: "forms/gad",
    remote_url: "forms/gad",
    component: <Gad />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/qol",
    remote_url: "patientmonitoring/tmpquestionnaires/qol",
    component: <StigmaForm />,
  },
  {
    local_url: "forms/qol",
    remote_url: "forms/qol",
    component: <StigmaForm />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/sunshine",
    remote_url: "patientmonitoring/tmpquestionnaires/sunshine",
    component: <SunshineAct />,
  },
  {
    local_url: "forms/sunshine",
    remote_url: "forms/sunshine",
    component: <SunshineAct />,
  },
  // {
  //   local_url: "forms/neuroconsent",
  //   remote_url: "forms/neuroconsent",
  //   component: <NeuroConsent />,
  // },
  
  {
    local_url: "patientmonitoring/tmpquestionnaires/icna",
    remote_url: "patientmonitoring/tmpquestionnaires/icna",
    component: <NeuroConsent />,
  },
  {
    local_url: "forms/icna",
    remote_url: "forms/icna",
    component: <NeuroConsent />,
  },
  {
    local_url: "forms/rpcs",
    remote_url: "forms/rpcs",
    component: <Rpcs />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/hipaarf",
    remote_url: "patientmonitoring/tmpquestionnaires/hipaarf",
    component: <Hipaa2 />,
  },
  {
    local_url: "forms/hipaarf",
    remote_url: "forms/hipaarf",
    component: <Hipaa2 />,
  },
  {
    local_url: "forms/newform",
    remote_url: "forms/newform",
    component: <NewForm />,
  },
  {
    local_url: "patientmonitoring/tmpquestionnaires/drfox",
    remote_url: "patientmonitoring/tmpquestionnaires/drfox",
    component: <DrFox />,
  },
  //patientmonitoring/tmpquestionnaires/
  {
    local_url: "patientmonitoring/tmpquestionnaires/drfox",
    remote_url: "patientmonitoring/tmpquestionnaires/drfox",
    component: <DrFox />,
  },
  {
    local_url: "forms/drfox",
    remote_url: "forms/drfox",
    component: <DrFox />,
  },
   // Patients 

  //  {
  //   local_url: "myprofile",
  //   remote_url: "myprofile",
  //   component: <MyProfile />,
  // },
  {
    local_url: "myprofile",
    remote_url: "myprofile",
    component: <MyProfile />,
  },
  {
    local_url: "myvitals",
    remote_url: "myvitals",
    component: <MyVitals />,
  },
  {
    local_url: "addvitals",
    remote_url: "addvitals",
    component: <AddVitals />,
  },
  {
    local_url: "patients/view",
    remote_url: "patients/view",
    component: <MyProfileCareadmin />,
  },
  {
    local_url: "mypatients/view",
    remote_url: "mypatients/view",
    component: <MyProfileDoctor />,
  },
  {
    local_url: "patients/view/:id",
    remote_url: "patients/view",
    component: <MyProfileCareadmin />,
  },
  {
    local_url: "myprofile/editinfo",
    remote_url: "myprofile/editinfo",
    component: <PatientsEditStepper />,
  },
  {
    local_url: "myprofile/editinfo/:id",
    remote_url: "myprofile/editinfo/:id",
    component: <PatientsEditStepper />,
  },
  {
    local_url: "patientprofile",
    remote_url: "patientprofile",
    component: <PatientProfile />,
  },
  {
    local_url: "profilecover",
    remote_url: "profilecover",
    component: <ProfileCover />,
  },

  {
    local_url: "form/view/rpcs",
    remote_url: "form/view/rpcs",
    component: <RpcsView />,
  },
  {
    local_url: "form/view/hit",
    remote_url: "form/view/hit",
    component: <HitView />,
  },
  {
    local_url: "form/view/odq",
    remote_url: "form/view/odq",
    component: <OxfordView />,
  },
  {
    local_url: "form/view/pcl",
    remote_url: "form/view/pcl",
    component: <PCLView />,
  },
  {
    local_url: "form/view/bdi",
    remote_url: "form/view/bdi",
    component: <BdiView />,
  },
  {
    local_url: "form/view/npi",
    remote_url: "form/view/npi",
    component: <NpiView />,
  },
  {
    local_url: "form/view/ess",
    remote_url: "form/view/ess",
    component: <EssView />,
  },
  {
    local_url: "form/view/ciss",
    remote_url: "form/view/ciss",
    component: <CissView />,
  },
  {
    local_url: "form/view/medical",
    remote_url: "form/view/medical",
    component: <MedicalView />,
  },
  {
    local_url: "form/view/phq",
    remote_url: "form/view/phq",
    component: <PhqView />,
  },
  {
    local_url: "form/view/nphq",
    remote_url: "form/view/nphq",
    component: <NphqView />,
  },
  {
    local_url: "form/view/gad",
    remote_url: "form/view/gad",
    component: <GadView />,
  },
  {
    local_url: "form/view/mna",
    remote_url: "form/view/mna",
    component: <MnaView />,
  },
  {
    local_url: "form/view/mb",
    remote_url: "form/view/mb",
    component: <MbView />,
  },
  {
    local_url: "form/view/lawton",
    remote_url: "form/view/lawton",
    component: <LawtonView />,
  },
  {
    local_url: "form/view/facialpain",
    remote_url: "form/view/facialpain",
    component: <FacialPainView />,
  },
  {
    local_url: "form/view/adhd",
    remote_url: "form/view/adhd",
    component: <AdhdView />,
  },
  {
    local_url: "form/view/hipaarf",
    remote_url: "form/view/hipaarf",
    component: <HippaView />,
  },
  {
    local_url: "form/view/sunshineact",
    remote_url: "form/view/sunshineact",
    component: <SunshineAct />,
  },
  {
    local_url: "forms/neuroqol",
    remote_url: "forms/neuroqol",
    component: <NeuroQol />,
  },
  {
    local_url: "form/view/neuro",
    remote_url: "form/view/neuro",
    component: <NeuroConsentView />,
  },
  {
    local_url: "form/view/cpcr",
    remote_url: "form/view/cpcr",
    component: <CpcrView />,
  },
  {
    local_url: "form/view/caic",
    remote_url: "form/view/caic",
    component: <CaicView />,
  },
  {
    local_url: "form/view/katz",
    remote_url: "form/view/katz",
    component: <ActivitiesFormView />,
  },
  {
    local_url: "form/view/qol",
    remote_url: "form/view/qol",
    component: <StigmaFormView />,
  },
  {
    local_url: "form/view/drfox",
    remote_url: "form/view/drfox",
    component: <DrFoxView />,
  },
  {
    local_url: "programs",
    remote_url: "programs",
    component: <AssignProgram />,
  },
  {
    local_url: "newprogram",
    remote_url: "newprogram",
    component: <AddPrograms />,
  },
  {
    local_url: "programforms",
    remote_url: "programforms",
    component: <ProgramForms />,
  },
  {
    local_url: "editprogramforms",
    remote_url: "editprogramforms",
    component: <EditProgram />,
  },
  {
    local_url: "programlist",
    remote_url: "programlist",
    component: <NewProgram />,
  },
  {
    local_url: "activitylog",
    remote_url: "activitylog",
    component: <ActivityLog />,
  },

  // {
  //   local_url: "myappointments",
  //   remote_url: "myappointments",
  //   component: <My Appointments />,
  // },

];

// for navbar menu items
export function navBarItems(List) {
  return (
    List.length > 0 &&
    PATHS.filter((path) => List.includes(path.remote_url)).map(
      (path) => path.local_url
    )
  );
}
